<script setup lang="ts">
import { type ProductType } from 'configs';
import { ArrowRightIcon } from 'lucide-vue-next';
import { Logger } from 'utils';
import {
  type AlgoliaFilterFacetGroup,
  DesignType,
} from '#gql/default';
import { getSpacingClassesForBlok } from '@/utils/storyblok';
import type { ProductCarouselStoryblok } from '@/storyblok-types';
import type { FilterCategoryWithFacets } from '@/types/list-page';

withDefaults(defineProps<{
  blok: ProductCarouselStoryblok;
  fullWidth?: boolean;
}>(), {  })

const GqlInstance = useGql();
const { locale } = useI18n();

const DESIGNS_TO_SHOW_IN_CAROUSEL = 12;
const DESIGNS_TO_SHOW_IN_EXPANDED_STATE = 30;
const expanded = ref(!!__props.blok.expandedByDefault);

const {
  filterScaffold,
  setFilterScaffold,
} = useFilterScaffold();

await setFilterScaffold();

const { getStoryblokUrl } = useStoryblokData();

const generateTopicFilters = (algoliaFilters: Omit<FilterCategoryWithFacets, 'productColor'>): AlgoliaFilterFacetGroup[] => {
  const result: AlgoliaFilterFacetGroup[] = [];

  Object.entries(algoliaFilters).forEach(([
    filterCategory,
    filterValues,
  ]) => {
    const matchingInitialFilters = filterScaffold.value.find((initialFilter) => initialFilter.key === filterCategory)?.filters;

    if (matchingInitialFilters) {
      let categoryFilterGroup = result.find((filter) => filter.key === filterCategory);
      if (!categoryFilterGroup) {
        categoryFilterGroup = {
          key: filterCategory,
          value: [],
        };
      }
      filterValues.forEach((filterValue) => {
        if (matchingInitialFilters.some((filter) => filter.key === filterValue)) {
          categoryFilterGroup.value.push({
            key: filterCategory,
            value: [ filterValue ],
          });
          return;
        }

        matchingInitialFilters.forEach((filter) => {
          const subFilter = isNestedFilter(filter) && filter.subItems.find((subItem) => subItem.key === filterValue);
          if (subFilter) {
            const facetKey = `${filterCategory}.${filter.key}`;
            const existingResult = categoryFilterGroup.value.find((resultItem) => resultItem.key === facetKey);

            if (existingResult) {
              existingResult.value.push(filterValue);
            } else {
              categoryFilterGroup.value.push({
                key: facetKey,
                value: [ filterValue ],
              });
            }
          }
        });
      });

      result.push(categoryFilterGroup);
    };
  });

  return result.filter((filter) => filter.value.length > 0);
};

const algoliaFilterFacetsGroups = computed<AlgoliaFilterFacetGroup[]>(() => {
  const filters = {
    designColor: __props.blok.algoliaFilterDesignColor?.map(String) ?? [],
    gender: __props.blok.algoliaFilterGender?.map(String) ?? [],
    occasion: __props.blok.alogliaFilterOccasion?.map(String) ?? [],
    orientation: __props.blok.alogliaFilterOrientation?.map(String) ?? [],
    product: __props.blok.algoliaFilterProduct?.map(String) ?? [],
    recipient: __props.blok.algoliaFilterRecipient?.map(String) ?? [],
    room: __props.blok.alogliaFilterRoom?.map(String) ?? [],
    style: __props.blok.algoliaFilterStyle?.map(String) ?? [],
    technique: __props.blok.algoliaFilterTechnique?.map(String) ?? [],
    topic: __props.blok.algoliaFilterTopic?.map(String) ?? [],
  };

  return generateTopicFilters(filters);
});

const designIds = computed(() => (__props.blok.designIds && __props.blok.designIds.trim().split(',')
  .map((designId) => designId.trim())) || undefined);

const { data } = await useAsyncData(
  `productCarousel-${locale.value}-${__props.blok._uid}`,
  async () => {
    try {
      return GqlInstance('Designs', {
        designIds: designIds.value,
        designType: __props.blok.preselectedDesignType
          ? __props.blok.preselectedDesignType.toUpperCase() as DesignType
          : DesignType.ALL,
        enableCreateOwnDesign: __props.blok.enableCreateOwnDesign ?? true,
        filterFacetGroups: algoliaFilterFacetsGroups.value,
        localeCode: locale.value,
        nodesPerPage: __props.blok.expandable
          ? DESIGNS_TO_SHOW_IN_EXPANDED_STATE
          : DESIGNS_TO_SHOW_IN_CAROUSEL,
        query: '',
        sort: __props.blok.preselectedSorting,
      });
    } catch (e) {
      Logger.error(`Could not fetch designs for product carousel "${__props.blok._uid}"`, e);
      return null;
    }
  },
  {
    watch: [
      designIds,
      algoliaFilterFacetsGroups,
    ],
  },
);

const designs = computed(() => data.value?.designs?.nodes ?? []);

const isExpandable = computed(() => __props.blok.expandable && designs.value.length > DESIGNS_TO_SHOW_IN_CAROUSEL);

const isDarkBackground = computed(() => isDarkColor(__props.blok.backgroundColor?.value));

const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));

const containerClass = computed(() => (__props.blok.containerSize === 'large'
  ? 'max-w-9xl'
  : 'max-w-5xl'));

const allProductFilters = filterScaffold.value.find((filter) => filter.key === 'product')?.filters.map((filter) => ({
  childKeys: 'subItems' in filter
    ? filter.subItems.map((subItem) => subItem.key)
    : [],
  parentKey: filter.key,
}));

const getActiveProductTypes = computed<ProductType[]>(() => {
  const algoliaFilterProduct = __props.blok?.algoliaFilterProduct as ProductType[];

  if (!algoliaFilterProduct) {
    return [];
  }

  return algoliaFilterProduct.flatMap((productFilter) => {
    const matchingFilter = allProductFilters?.find((f) => f.parentKey === productFilter);

    if (matchingFilter) {
      return matchingFilter.childKeys as ProductType[] ?? [];
    }

    return [ productFilter ];
  });
});

const buttonClass = computed(() => (isDarkBackground.value
  ? 'border-white/30 text-white hover:!border-transparent hover:!bg-white/30'
  : 'text-dark border-dark/10 hover:!border-transparent hover:!bg-dark/10'));

const blokLink = computed(() => __props.blok.link && getStoryblokUrl(__props.blok.link));

const sortedDesigns = computed(() => designs.value.sort((a, b) => (designIds.value?.indexOf(a.objectId) ?? 0) - (designIds.value?.indexOf(b.objectId) ?? 0)));
</script>

<template>
  <div
    v-editable="blok"
    :class="[isDarkBackground ? 'text-white' : 'text-dark', spacingClasses]"
    :style="{ backgroundColor: blok.backgroundColor.value }"
  >
    <div
      class="relative @container"
      :class="[containerClass, fullWidth ? 'w-full' : 'container overflow-hidden']"
    >
      <div class="mb-4 flex flex-wrap items-end justify-between gap-6 lg:flex-nowrap">
        <div>
          <h3
            v-if="blok.headline"
            class="my-0 text-3xl font-black"
          >
            {{ blok.headline }}
          </h3>
          <p
            v-if="blok.subline"
            class="justify my-0 flex text-lg font-medium opacity-80"
          >
            {{ blok.subline }}
          </p>
        </div>

        <div class="hidden shrink-0 items-center gap-2 md:flex">
          <SimilarDesignsExpandButton
            v-if="isExpandable"
            :expanded="expanded"
            @update:expanded="expanded = $event"
          />

          <Button
            v-if="blokLink"
            as-child
            class="!shadow-none"
            size="small"
            variant="outline"
            :class="buttonClass"
          >
            <NuxtLink :to="getStoryblokUrl(blok.link)">
              {{ blok.linkText }}
              <ArrowRightIcon class="size-4" />
            </NuxtLink>
          </Button>
        </div>
      </div>

      <div
        v-if="expanded"
        class="grid w-full grid-cols-2 items-start gap-4 py-4 @2xl:grid-cols-3 @5xl:grid-cols-4 @6xl:grid-cols-5"
      >
        <div
          v-for="design in sortedDesigns"
          :key="design.objectId"
        >
          <DesignTile
            show-product-type
            class="text-dark"
            :active-product-types="getActiveProductTypes"
            :design="design"
          />
        </div>
      </div>

      <div
        v-else
        :class="{ '-mx-2': !fullWidth }"
      >
        <UiScroller
          button-style-dark
          never-hide-buttons
          class="product-carousel-scroller"
          position-button-left="left-8"
          position-button-right="right-8"
          :class="{ '-mx-4 !w-auto md:-mx-5 lg:-mx-10': fullWidth }"
          :scroll-content-class="cn( {'scroll-pl-2 md:scroll-pl-3 lg:scroll-pl-8': fullWidth })"
          :scroll-distance="290"
        >
          <template #scrollerContent>
            <div
              v-for="design in sortedDesigns.slice(0, DESIGNS_TO_SHOW_IN_CAROUSEL)"
              :key="design.objectId"
              class="first mr-px flex w-72 shrink-0 grow-0 snap-start px-2 pb-8 pt-4"
              :class="{ 'first:ml-2 last:mr-2 md:first:ml-3 md:last:mr-3 lg:first:ml-8 lg:last:mr-8': fullWidth }"
            >
              <DesignTile
                show-product-type
                class="text-dark"
                :active-product-types="getActiveProductTypes"
                :design="design"
              />
            </div>
          </template>
        </UiScroller>
      </div>

      <div class="flex items-center justify-center gap-2 md:hidden">
        <SimilarDesignsExpandButton
          v-if="isExpandable"
          :expanded="expanded"
          @update:expanded="expanded = $event"
        />

        <Button
          v-if="blokLink"
          as-child
          class="!shadow-none"
          size="small"
          variant="outline"
          :class="buttonClass"
        >
          <NuxtLink :to="getStoryblokUrl(blok.link)">
            {{ blok.linkText }}
            <ArrowRightIcon class="size-4" />
          </NuxtLink>
        </Button>
      </div>
    </div>
  </div>
</template>
